<template>
  <v-row wrap no-gutters>
    <v-col :cols="properties.action !== 'INLINE' ? '9' : '12'">
      <v-select
        :key="`${step.id}-action`"
        v-model="properties.action"
        :items="formattedExecuteProcessActions"
        :label="$lang.labels.action"
        outlined
        dense
        required
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="executeProcess-action"
        @change="handleChange('action', $event)"
      ></v-select>
    </v-col>
    <v-col
      v-if="properties.action !== 'INLINE'"
      cols="3"
      class="pl-sm-1"
    >
      <v-select
        :key="`${step.id}-priority`"
        v-model="properties.priority"
        :items="priorities"
        :label="$lang.labels.priority"
        outlined
        dense
        required
        data-cy="executeProcess-priority"
        :readonly="!canEdit"
        @change="handleChange('priority', $event)"
      ></v-select>
    </v-col>
    <v-col cols="12">
      <div class="d-inline-flex justify-space-between" style="width: 100%">
        <v-autocomplete
          :key="`${step.id}-processName`"
          v-model="properties.processName"
          outlined
          dense
          :items="processes"
          :loading="isLoadingProcesses"
          :search-input.sync="processesSearchRequest"
          clearable
          hide-no-data
          item-text="name"
          item-value="name"
          :label="$lang.labels.process"
          :placeholder="$lang.actions.startTyping"
          prepend-inner-icon="mdi-cloud-search-outline"
          :readonly="!canEdit"
          class="mr-1"
          :hint="showProcessNameHint && properties?.processName 
            ? `${$lang.hints.processNameNoExists}: ${properties.processName}` 
            : ''"
          persistent-hint
          @change="handleChange('processName', $event)"
        ></v-autocomplete>
        <v-btn
          text
          class="mx-0"
          color="primary"
          :disabled="!processId"
          @click="openProcess()"
        >
          {{ $lang.actions.openProcess }}
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-childEventIdPath`"
        v-model="properties.childEventIdPath"
        outlined
        dense
        :label="$lang.labels.childEventIdPath"
        required
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        @change="handleChange('childEventIdPath', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="py-1">
      <custom-external-process-input-key-value
        v-if="showExecuteInputs && properties.processName"
        :key="`${step.id}-inputs`"
        type="input"
        :step-type="'EXECUTE_PROCESS'"
        :can-edit="canEdit"
        :data="properties.input"
        :key-list="executeInputsKeyList"
        @dataChanged="handleChange('input', $event)"
      />
      <v-progress-circular v-if="!showExecuteInputs && properties.processName" indeterminate color="primary" />
    </v-col>
    <v-col v-if="properties.action !== 'ASYNC' && properties.processName" cols="12" class="py-1">
      <custom-external-process-input-key-value
        v-if="showExecuteOutputs && properties.processName"
        :key="`${step.id}-outputs`"
        type="output"
        :step-type="'EXECUTE_PROCESS'"
        :can-edit="canEdit"
        :data="properties.output"
        :key-list="executeOutputsKeyList"
        @dataChanged="handleChange('output', $event)"
      />
      <v-progress-circular v-if="!showExecuteOutputs && properties.processName" indeterminate color="primary" />
    </v-col>
  </v-row>
</template>

<script>
import {
  getProcessesUsingGET as getProcesses,
  getProcessByIdUsingGET as getProcess,
  getValidationRuleByIdUsingGET as getValidationRule
} from '@/utils/api'
import CustomExternalProcessInputKeyValue from '../CustomExternalProcessInputKeyValue'
import StepMixin from './StepMixin'
import { EXECUTE_PROCESS_ACTIONS } from './constants'

export default {
  name: 'ExecuteProcessStep',
  components: {
    CustomExternalProcessInputKeyValue
  },
  mixins: [StepMixin],
  props: {
    resourceIsSystem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      processesSearchRequest: '',
      isLoadingProcesses: false,
      processId: 0,
      processes: [],
      executeInputsKeyList: [],
      executeOutputsKeyList: [],
      showExecuteInputs: false,
      showExecuteOutputs: false,
      showProcessNameHint: false
    }
  },
  computed: {
    formattedExecuteProcessActions() {
      return EXECUTE_PROCESS_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    priorities() {
      return Array.from({ length: 128 }, (_, index) => index + 1)
    }
  },
  watch: {
    processesSearchRequest: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchProcesses(val)
        } else {
          this.processId = 0
        }
      }
    }
  },
  created() {
    setTimeout(() => {
      this.processesSearchRequest = this.properties.processName
    }, 10)
    if (this.properties.processName) this.onChangeProcessName()
    if (!this.properties.output) this.$set(this.properties, 'output', {})
    if (!this.properties.input) this.$set(this.properties, 'input', {})
    if (!this.properties.action) this.properties.action = 'SYNC'
  },
  methods: {
    searchProcesses(val = '') {
      this.isLoadingProcesses = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.isSystem = this.resourceIsSystem

      getProcesses(obj)
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false

          const isSelected = res.data.data.items.find((x) => x.name === this.processesSearchRequest)

          if (isSelected) this.processId = isSelected.id
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })         
        })
    },
    onChangeProcessName() {
      this.executeInputsKeyList = []
      this.executeOutputsKeyList = []
      this.showExecuteInputs = false
      this.showExecuteOutputs = false
      this.showProcessNameHint = false
      const obj = {}

      obj.name = this.properties.processName

      if (!this.properties?.processName) return

      getProcesses(obj)
        .then((res) => {
          let foundInputValidations = null
          let foundOutputValidations = null
          const localProcess = res.data.data.items.find((x) => x.name === this.properties.processName)

          if (!localProcess) {
            this.isLoadingProcesses = false
            this.executeInputsKeyList = []
            this.executeOutputsKeyList = []
            this.showExecuteOutputs = true
            this.showExecuteInputs = true
            this.showProcessNameHint = true

            return
          }

          getProcess({ id: localProcess.id })
            .then(async (res) => {
              if (res?.data?.data?.inputValidationRuleId) {
                foundInputValidations = await this.fetchValidationRecursive(res.data.data.inputValidationRuleId)
                this.executeInputsKeyList = foundInputValidations.map((x) => x.name)
                this.showExecuteInputs = true
              } else {
                this.showExecuteInputs = true
              }
              if (res?.data?.data?.outputValidationRuleId) {
                foundOutputValidations = await this.fetchValidationRecursive(res.data.data.outputValidationRuleId)
                this.executeOutputsKeyList = foundOutputValidations.map((x) => x.name)
                this.showExecuteOutputs = true
              } else {
                this.showExecuteOutputs = true
              }
            })
            .catch((err) => {
              this.isLoadingProcesses = false
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })
            })
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    async fetchValidationRecursive(validation) {

      const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

      const fetchedValidations = []
      const tempValidation = (validationId) => {
        getValidationRule({ id: validationId }).then((res) => {
          fetchedValidations.push(res.data.data.fields)
          if (res.data.data.includes && res.data.data.includes.length > 0) {
            res.data.data.includes.forEach((include) => {
              tempValidation(include)
            })
          }
        })
          .catch((err) => {
            console.log(err)
          })
      }

      await tempValidation(validation)

      await pause(1500)

      return fetchedValidations.flat(Infinity).map((x) => {
        return { ...x, name: `$.${x.name}` }
      })
    },
    openProcess() {
      if (this.processId) 
        window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.processId}`, '_blank')
    },
    handleChange(key, val) {
      if (key === 'action') {
        switch (val) {
        case 'ASYNC':
          this.properties.output = {}
          break
        case 'INLINE':
          this.properties.priority = ''
          break
        default:
          this.properties.priority = 64
          break
        }
      } else if (key === 'processName') {
        this.onChangeProcessName()
      }
      this.properties[key] = val
    }
  }
}
</script>
