<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6" class="d-inline-flex">
        <h3>{{ $lang.labels[type] }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ text: '', value: '' }), editRow(deconstructedData.length - 1)"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col v-if="duplicatedKeys" cols="12" class="pb-2">
        <v-alert dense color="error" style="color: black">{{ $lang.errors.duplicatedKeys }}</v-alert>
      </v-col>
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-card :key="i" flat class="pa-2 mb-1" style="position: relative">
            <div class="d-inline-flex" style="position: absolute; top: 8px; right: 8px">
              <v-btn
                icon
                small
                :disabled="!canEdit"
                @click="editRow(i)"
              >
                <v-icon small color="info">mdi-pen</v-icon>
              </v-btn>
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <v-row no-gutters align="center" class="pb-1">
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.key }}</p>
                <p class="pb-0 mb-0">{{ item.text }}</p>
              </v-col>
              <v-col cols="12" class="my-1">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.value }}</p>
                <p class="pb-0 mb-0" style="min-height: 24px">{{ item.value.length > 150 ? `${item.value.substring(0, 147)}...` : item.value }}</p>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-if="showExecuteProcessEditor" v-model="showExecuteProcessEditor" max-width="71%">
      <global-execute-process-editor
        :item="selectedItem"
        :with-buttons="true"
        :can-edit="canEdit"
        :step-type="stepType"
        :key-list="filteredKeys"
        :single-step="singleStep"
        @closeDialog="closeEdit()"
        @fromGlobalEditor="updateFromEditor($event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import globalExecuteProcessEditor from '@/components/ui/GlobalExecuteProcessEditor.vue'

export default {
  components: { globalExecuteProcessEditor },
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    full: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    keyList: {
      type: Array,
      default: () => {
        return []
      }
    },
    singleStep: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: () => ({
    showExecuteProcessEditor: false,
    deconstructedData: [],
    emailKeys: ['locale', 'subject'],
    slackKeys: ['locale', 'message'],
    languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
    searchSync: '',
    isBodyScript: false,
    bodyScript: '',
    headerPredefinedValues: [
      '\'application/json\'',
      '\'application/xml\'',
      '\'application/x-www-form-urlencoded\'',
      '\'multipart/form-data\''
    ],
    selectedItem: null,
    selectedKeys: [],
    duplicatedKeys: false,
    initDone: false
  }),
  computed: {
    ...mapState('app', ['userSettings']),
    filteredKeys() {
      return this.keyList.filter((x) => !this.selectedKeys.includes(x))
    },
    availableLanguages() {
      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    }
  },
  watch: {
    searchSync: {
      handler(val) {
        const tempSearch = this.availableLanguages.find((x) => x.text === val)
        const localeData = this.deconstructedData.find((x) => x.text === 'locale')

        if (tempSearch) localeData.value = tempSearch.value
      }
    },
    deconstructedData: {
      handler() {
        const tempData = {}

        this.deconstructedData.forEach((item) => {
          if (item.text && item.text.name && item.value) tempData[item.text.name] = item.value
          if (item.text && !item.text.name && item.value) tempData[item.text] = item.value
        })

        this.selectedKeys = this.deconstructedData.map((x) => x.text)
        this.duplicatedKeys = !!this.hasDuplicates(this.selectedKeys)
        this.$emit('dataChanged', tempData)
      },
      deep: true
    }
  },
  created() {
    for (const row in this.data) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.data.hasOwnProperty(row)) {
        this.deconstructedData.push({ text: row , value: this.data[row] })
      }
    }
  },
  methods: {
    updateFromEditor(data) {
      this.deconstructedData[this.selectedItemIndex].text = data.key
      this.deconstructedData[this.selectedItemIndex].value = data.value
      this.showExecuteProcessEditor = false
      this.selectedItemIndex = null
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    },
    editRow(i) {
      this.selectedItem = this.deconstructedData[i]
      this.selectedItemIndex = i
      this.showExecuteProcessEditor = true
    },
    closeEdit() {
      if (this.selectedItem?.value === '' || this.selectedItem?.text === '') {
        this.deconstructedData.splice(this.selectedItemIndex, 1)
      }
      this.selectedItem = null
      this.selectedItemIndex = null
      this.showExecuteProcessEditor = false
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
